import React, { useState } from 'react';
import './Chat.css';
import IconButton from './IconButton';
import { ReactComponent as PaperPlane } from '../../assets/SVG/PaperPlane.svg';
import { ReactComponent as ProfileCircle } from '../../assets/SVG/ProfileCircle.svg';
import { useGlobalSettingsContext } from "../../contexts/GlobalSettingsContext";
import { useSocket } from '../../contexts/SocketContext';
import { useEffect, useContext } from 'react';
import ChatMessagesContext from './contexts/ChatMessagesContext';

function Chat() {
    const { userName } = useGlobalSettingsContext();
    const { room } = useSocket();
    //const [chatMessages, setChatMessages] = useState([]);
    const {chatMessages, setChatMessages} = useContext(ChatMessagesContext);
    const [newMessage, setNewMessage] = useState('');
    
    useEffect(() => {
        room.onMessage('chatMessage', (message) => {
            setChatMessages([...chatMessages, message]);
            //chatMessages.setChatMessages([...chatMessages.chatMessages, message]);
        });
    }, [chatMessages]);



    const handleSendMessage = () => {
        if (newMessage.trim() !== '') {
            let toSend = {
                name: userName,
                message: newMessage,
                timeStamp: Date.now(),
                recipient: "Global"
            }
            room.send('chatMessage', toSend)
        }
        setNewMessage('');
    };

    return (
        <div className="chat">
            <div className="chat-box">
                {chatMessages.map((message, index) => (
                    <div key={index} className="message">
                        <div className="profile-circle-container">
                            <ProfileCircle />
                        </div>
                        <div className="message-content-container">
                            {/*Loop through the list of message JSONs and get the name of the send and message they sent.*/}
                            <h3 className="message-header">{message.name}</h3>
                            <div className="message-content">{message.message}</div>
                        </div>
                    </div>
                ))}
            </div>
            <div className="chat-input-container">
                <div className="chat-input">
                    <input
                        type="text"
                        value={newMessage}
                        onChange={(e) => setNewMessage(e.target.value)}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                e.preventDefault(); // Prevents the default Enter behavior (e.g., new line)
                                handleSendMessage();
                            }
                        }}
                    />
                    <IconButton icon={<PaperPlane />} onClick={handleSendMessage} small />
                </div>
            </div>
        </div>
    );
}

export default Chat;
