import React, { useState } from 'react';
import './VolumeControl.scss';

const VolumeControl = () => {
    const [volume, setVolume] = useState(1)
  
    return (
        <div className='volume-control__container'>
            <input
                className='volume-control__slider'
                type="range"
                min={0}
                max={1}
                step={0.01}
                value={volume}
                onChange={event => {setVolume(event.target.valueAsNumber)}}/>
        </div>
    )
};

export default VolumeControl;
