import React, {useState} from 'react';
import EnterScreenBackgroundImage from '../assets/EnterScreenBackground.png'
import BBLogoImage from '../assets/BBLogo.png'
import './Jost-Regular.css';

function EnterScreen() {

    const buttonHeight = '75px';
    const buttonBorders = 'rgba(238, 238, 238, 0.5)';
    const buttonColour = '#ffffff';
    const buttonTextColour = '#000000';
    const [showing, setShowing] = useState(true);
    const [fade, setFade] = useState(false);
    const fadeOutTime = 0.5;

    const buttonStyle = {
        position: 'absolute',
        width: '40vw', // Adjust the percentage width as needed
        height: buttonHeight, // Adjust the height as needed
        borderRadius: buttonHeight,
        backgroundColor: '#FFFFFF',
        color: '#000000',
        fontSize: '16px',
        cursor: 'pointer',
        border: '5px solid rgba(238, 238, 238, 0.5)',
        outline: 'none',
        top: '60%'
    };

    const containerStyle = {
        position: 'absolute',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh', // Full viewport height
        widht: '100vw',
        transition: `opacity ${fadeOutTime}s`,
        opacity: !fade ? 1 : 0,
        zIndex: 5000
    };

    const backgroundStyle = {
        width: '100vw', // Make the image fill the entire container
        height: '100vh', // Make the image fill the entire container
    }

    const logoStyle = {
        position: 'absolute',
        top: '13%',
        width: '10%',
        height: 'auto'
    }

    const titleStyle = {
        font: '36px Jost',
        color: '#ffffff',
        top: '28%',
        position: 'absolute',
    }

    const textStyle = {
        font: '18px Jost',
        color: '#ffffff',
        top: '40%',
        position: 'absolute',
        textAlign: 'center'
    }

    const onClick = () => {
        setFade(true);
        setTimeout(() => setShowing(false), fadeOutTime * 1000);
    }

    return (
            showing ? (<div style={containerStyle}>
                <img
                    src={EnterScreenBackgroundImage}
                    style={backgroundStyle}
                />
                <img
                    src={BBLogoImage}
                    style={logoStyle}
                />
                <p style={titleStyle}>
                    PS Spaces Showcase
                </p>
                <p style={textStyle}>
                    Welcome to a demo showcasing round 1 of the “PS Spaces” PoC.
                    <br></br>
                    Click here to learn more about the project.
                </p>
                <button style={buttonStyle} onClick={onClick}>
                    ENTER
                </button>
            </div>)
            :
            <div></div>
    )
}

export default EnterScreen;