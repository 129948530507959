import React, { useEffect, useState} from 'react';
import { ObjectLoader } from 'three';
import { useThree, extend } from '@react-three/fiber';
import { EffectComposer } from '@react-three/postprocessing';
import { RenderPixelatedPass } from 'three-stdlib';
import { Effects } from '@react-three/drei';

// Extend the RenderPixelatedPass
extend({ RenderPixelatedPass });

// Your other components
const Background = () => {
  const { gl } = useThree();

  React.useEffect(() => {
    gl.setClearColor('black');
  }, [gl]);

  return null;
};

const TestScene = ({ characterRef }) => {
  const [myScene, setMyScene] = useState(null);


  useEffect(() => {
    console.log('TestScene mounted');
  
    return () => {
      console.log('TestScene unmounted');
    };
  }, []);

  useEffect(() => {

    const loader = new ObjectLoader();
    loader.load(`${process.env.PUBLIC_URL}/assets/scenes/testScene.json`, (loadedScene) => {
      setMyScene(loadedScene);
    });
  }, []);

  return (
    <>
      {myScene && <primitive object={myScene} />}
      <Background />
      {/* Add the EffectComposer and Pixelation effect */}
      {/* <EffectComposer> */}
        {/* <CustomPixelationEffect pixelSize={4.0} /> */}
        {/* Other passes */}
      {/* </EffectComposer> */}
      {/* <Effects>
        <renderPixelatedPass args={[resolution, 2, scene, camera]} />
      </Effects> */}
    </>
  );
};

export default TestScene;

