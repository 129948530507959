import React, {useEffect, useState} from 'react';
import ViewSelection from './ViewSelection/ViewSelection';
import VolumeControl from './VolumeControl/VolumeControl';
import "./Settings.scss"
import {useGlobalSettingsContext} from "../../contexts/GlobalSettingsContext";

function Settings() {
    const {userName, setUserName} = useGlobalSettingsContext();
    const [nameInput, setNameInput] = useState('Guest');
    const [isNameSaved, setIsNameSaved] = useState(true);

    useEffect(() => {
        setNameInput(userName);
    }, []);

    // Set the username in the global setting context
    const handleSaveName = () => {
        setIsNameSaved(true);
        setUserName(nameInput);
    }

    return (
        <div className="settings desktop">
            <h3>Settings</h3>
            <div className='name-input'>
                <h4 className='setting-header'>Name</h4>
                <div class="name-input__container">
                    <input class="input-box"
                           type="text"
                           value={nameInput}
                           onChange={(e) => {
                               setIsNameSaved(false);
                               setNameInput(e.target.value)
                           }}
                    />
                    <button class={"button "+ (isNameSaved ? "saved" : "")}
                            onClick={handleSaveName} >Save</button>
                </div>
            </div>
            <div className='pov-control'>
                <h4 className='setting-header'>POV</h4>
                <ViewSelection/>
            </div>
            <div className='volume-control'>
                <h4 className='setting-header'>Volume</h4>
                <VolumeControl/>
            </div>
        </div>
    );
}

export default Settings;
