import React, { createContext, useState } from "react";

const ChatMessagesContext = createContext();

export function ChatMessagesContextProvider(props) {
    const [chatMessages, setChatMessages] = useState([]);

    return (
        <ChatMessagesContext.Provider value={{chatMessages, setChatMessages}}>
            {props.children}
        </ChatMessagesContext.Provider>
    );
}
export default ChatMessagesContext;
