import React, { useEffect, useContext, useRef, useState, useMemo } from 'react';
import { useThree, useFrame } from '@react-three/fiber';
import { WorldContext } from '../contexts/WorldContext';
import { GlobalStoreContext } from './GlobalNav/store/GlobalStore.js';
import * as THREE from 'three';
import { MeshStandardMaterial } from 'three'



const ProximityTile = ({
  characterRef,
  position = [0, 0, 0],
  rotation = [0, 0, 0],
  size = [5, 5, 5],
  ID,
  socket,
  isQuestion,
  answer
}) => {
  const tileBox = new THREE.Box3();
  const { store } = useContext(GlobalStoreContext);
  const [tileEntered, setTileEntered] = useState(false);
  const [isCorrectAnswer, setIsCorrectAnswer] = useState(false);

  const faceOpacity = 1;
  const isTransparent = true;
  const tileColourIdle = '#775500';
  const tileColourStepped = '#441100';
  const tileColourCorrect = "#008000";

  const createHtmlTexture = (colour) => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    canvas.width = 512; // Set the width of the canvas
    canvas.height = 512; // Set the height of the canvas
    const textSize = canvas.height / 2;
    const textX = canvas.width / 2;
    const textY = canvas.height / 2 + textSize / 2;
    ctx.fillStyle = colour;
    ctx.fillRect(0, 0, canvas.width, canvas.height);
    // Add HTML content to the canvas
    ctx.font = `${textSize}px Arial`;
    ctx.fillStyle = 'white';
    ctx.textAlign = 'center';

    //ctx.drawImage(image, 0, 0);
    ctx.fillText(ID, textX, textY);

    const texture = new THREE.CanvasTexture(canvas);

    texture.needsUpdate = true;
    return texture;
  };

  const cubeMaterialsIdle = useMemo(() => {
    return [
      new MeshStandardMaterial({ color: tileColourIdle, opacity: faceOpacity, transparent: isTransparent }),
      new MeshStandardMaterial({ color: tileColourIdle, opacity: faceOpacity, transparent: isTransparent }),
      // Top Face
      new MeshStandardMaterial({ map: createHtmlTexture(tileColourIdle), opacity: faceOpacity, transparent: isTransparent }),
      new MeshStandardMaterial({ color: tileColourIdle, opacity: faceOpacity, transparent: isTransparent }),
      new MeshStandardMaterial({ color: tileColourIdle, opacity: faceOpacity, transparent: isTransparent }),
      new MeshStandardMaterial({ color: tileColourIdle, opacity: faceOpacity, transparent: isTransparent }),
    ];
  },[]);


  const cubeMaterialsStepped = useMemo(() => {
    return [
      new MeshStandardMaterial({ color: tileColourStepped, opacity: faceOpacity, transparent: isTransparent }),
      new MeshStandardMaterial({ color: tileColourStepped, opacity: faceOpacity, transparent: isTransparent }),
      // Top Face
      new MeshStandardMaterial({ map: createHtmlTexture(tileColourStepped), opacity: faceOpacity, transparent: isTransparent }),
      new MeshStandardMaterial({ color: tileColourStepped, opacity: faceOpacity, transparent: isTransparent }),
      new MeshStandardMaterial({ color: tileColourStepped, opacity: faceOpacity, transparent: isTransparent }),
      new MeshStandardMaterial({ color: tileColourStepped, opacity: faceOpacity, transparent: isTransparent }),
    ];
  },[]);

  const cubeMaterialsCorrect = useMemo(() => {
    return [
      new MeshStandardMaterial({ color: tileColourCorrect, opacity: faceOpacity, transparent: isTransparent }),
      new MeshStandardMaterial({ color: tileColourCorrect, opacity: faceOpacity, transparent: isTransparent }),
      // Top Face
      new MeshStandardMaterial({ map: createHtmlTexture(tileColourCorrect), opacity: faceOpacity, transparent: isTransparent }),
      new MeshStandardMaterial({ color: tileColourCorrect, opacity: faceOpacity, transparent: isTransparent }),
      new MeshStandardMaterial({ color: tileColourCorrect, opacity: faceOpacity, transparent: isTransparent }),
      new MeshStandardMaterial({ color: tileColourCorrect, opacity: faceOpacity, transparent: isTransparent }),
    ];
  },[]);

  useEffect(() => {
    // Assuming the tile is positioned and scaled at its center
    tileBox.setFromCenterAndSize(
      new THREE.Vector3(...position),
      new THREE.Vector3(...size)
    );
  }, [position, size]);

  useEffect(() => {
    return () => {
      setTileEntered(false);
      store.setSelectedTile(null);
    };
  }, []);


  useFrame(() => {
    if (!characterRef || !characterRef.current) {
      console.log("returning");
      return;
    }
    let characterPosition = new THREE.Vector3().setFromMatrixPosition(
      characterRef.current.matrixWorld
    );

    //Make sure tile is properly rendered 
    if (tileBox.min.x != Infinity) {
      if (isQuestion){
        if (tileBox.containsPoint(characterPosition)) {
          //console.log("Entered");
          setTileEntered(true);

          if (store.selectedTile !== ID) {
            store.setSelectedTile(ID);
            socket.send('playerAnswer', ID)
          }
        }
        if (!tileBox.containsPoint(characterPosition) && tileEntered) {
          setTileEntered(false);
          store.setSelectedTile(null);
          socket.send('playerAnswer', null)

        }
      } else {
        if (ID === answer && !isCorrectAnswer){
          setIsCorrectAnswer(true);
        }
      }
    }
  });



  return (
    <>
          <mesh position={position} rotation={rotation} scale={size} material={
            (isCorrectAnswer && ID === answer && !isQuestion) ? cubeMaterialsCorrect
            : !tileEntered ? cubeMaterialsIdle : cubeMaterialsStepped
            }>
            <boxGeometry />
          </mesh>

    </>
  );
};

export default ProximityTile;