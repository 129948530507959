import React, { useRef, useState, useContext, useEffect, useMemo } from 'react';
import * as THREE from 'three';
import { VideoStateContext } from '../contexts/VideoStateContext';
import { DoubleSide, MeshBasicMaterial, PlaneGeometry, Mesh, VideoTexture, MeshStandardMaterial, BoxGeometry, MeshFaceMaterial } from 'three';
import { useSocket } from '../contexts/SocketContext';
import { useGlobalSettingsContext } from "../contexts/GlobalSettingsContext";
import GlobalSettingsContext from '../contexts/GlobalSettingsContext';
import DynamicTextureTV from '../scenes/DynamicTextureTV';
import mountains from "../assets/mountains.jpeg";
import instructionScreen from "../assets/instructionScreen.png";
import questionBackground from "../assets/questionBackground.png";
import { current } from 'immer';
import ProximityTile from './ProximityTile';
import GlobalStoreContext from './GlobalNav/store/GlobalStore';
import TimerOverlay from './TimerOverlay';
import './Jost-Regular.css';

import { Box, Text } from '@react-three/drei';
import { decimalToMinuteSecond, secondsToMinuteSecond } from './HelperFunctions';


export default function VideoPlayer({ videoPath, characterRef }) {
    const { room } = useSocket();
    const { videoState, setVideoState, isLocallyPaused, setIsLocallyPaused, awaitingSync, setShouldSyncImmediately, shouldSyncImmediately, setAwaitingSync } = useContext(VideoStateContext);
    const { userName } = useGlobalSettingsContext();
    const { store } = useContext(GlobalStoreContext);

    const videoRef = useRef(null);
    const [videoTexture, setVideoTexture] = useState(null);
    const [metadataLoaded, setMetadataLoaded] = useState(false);
    const [isConnected, setIsConnected] = useState(true);

    //const [currentState, setState] = useState('questionState1');
    const [currentState, setState] = useState('videoState');
    const [playerScores, setPlayerScores] = useState(null);
    const [answer, setAnswer] = useState(null);

    const textFont = 'Jost';
    
    // 1. Setup videoRef and associated video events
    useEffect(() => {
        if (!videoRef.current) {
            videoRef.current = document.createElement('video');
            videoRef.current.src = videoPath;
            videoRef.current.muted = true;
            videoRef.current.loop = true;
            videoRef.current.playsInline = true;

        }

    }, [videoPath]);

    // Initialize image
    const [questionBackgroundImage] = useState(new Image());
    const [instructionScreenImage] = useState(new Image());
    useEffect(() => {
        questionBackgroundImage.src = questionBackground;
        instructionScreenImage.src = instructionScreen;
    }, [questionBackgroundImage, instructionScreenImage]);

    useEffect(() => {
        if (!videoRef.current) return;

        const video = videoRef.current;
        const handleMetadataLoaded = () => {

            setVideoState(prevState => ({ ...prevState, duration: video.duration, currentTime: 0 }));
            setMetadataLoaded(true);
        };
        video.addEventListener('loadedmetadata', handleMetadataLoaded);
        return () => {
            video.removeEventListener('loadedmetadata', handleMetadataLoaded);
        };
    }, [setVideoState]);

    useEffect(() => {
        if (videoRef.current) {
            setVideoTexture(new VideoTexture(videoRef.current));
        }
    }, []);

    // 2. Set up the message handlers
    useEffect(() => {
        if (room) {
            room.onMessage('videoUpdate', (message) => {
                //console.log("Receiving videoUpdate from server", message);
                store.setTimeInformation(`${secondsToMinuteSecond(message.currentTime)}/${secondsToMinuteSecond(message.duration)}`);
                //console.log(store.timeInformation)
                if (metadataLoaded) {
                    setVideoState(prevState => ({ ...prevState, ...message, duration: prevState.duration }));
                    if (awaitingSync) {
                        videoRef.current.play();
                        setAwaitingSync(false);
                    }
                }
            });
            room.onMessage('timeInformation', (message) => {
                store.setTimeInformation(`${secondsToMinuteSecond(message.currentTime)}/${secondsToMinuteSecond(message.duration)}`);
            });
            // State switcher
            room.onMessage('currentState', (message) => {
                
                setState(message);
                if (message === 'questionState0' || message === 'answerState0') {
                    setTextDriver(() => (ctx) => {
                        ctx.font = `bold 60px ${textFont}`;
                        const outlineColour = (alpha) => { return `rgba(100, 100, 100, ${alpha})` };
                        const boxColour = (alpha) => { return `rgba(255, 255, 255, ${alpha})` };
                        const textColour = (alpha) => { return `rgba(0, 0, 0, ${alpha})` };
                        const scaleFactor = 120;
                        drawBox(ctx, 1.5 * scaleFactor, 1.4 * scaleFactor, 13 * scaleFactor, 3 * scaleFactor, 0.4 * scaleFactor, outlineColour(1), boxColour(1), textColour(1), "Question 1- \nWhere does the train depart from to get to Machu Pichu?");
                        ctx.font = `48px ${textFont}`;
                    })
                }
                else if (message === 'questionState1' || message === 'answerState1') {
                    setTextDriver(() => (ctx) => {
                        ctx.font = `bold 60px ${textFont}`;
                        const outlineColour = (alpha) => { return `rgba(100, 100, 100, ${alpha})` };
                        const boxColour = (alpha) => { return `rgba(255, 255, 255, ${alpha})` };
                        const textColour = (alpha) => { return `rgba(0, 0, 0, ${alpha})` };
                        const scaleFactor = 120;
                        drawBox(ctx, 1.5 * scaleFactor, 1.4 * scaleFactor, 13 * scaleFactor, 3 * scaleFactor, 0.4 * scaleFactor, outlineColour(1), boxColour(1), textColour(1), "Question 2- \nWhat is the name of the trail to Machu Pichu?");
                        ctx.font = `48px ${textFont}`;
                    });
                }
                else if (message === 'questionState2' || message === 'answerState2') {
                    setTextDriver(() => (ctx) => {
                        ctx.font = `bold 60px ${textFont}`;
                        const outlineColour = (alpha) => { return `rgba(100, 100, 100, ${alpha})` };
                        const boxColour = (alpha) => { return `rgba(255, 255, 255, ${alpha})` };
                        const textColour = (alpha) => { return `rgba(0, 0, 0, ${alpha})` };
                        const scaleFactor = 120;
                        drawBox(ctx, 1.5 * scaleFactor, 1.4 * scaleFactor, 13 * scaleFactor, 3 * scaleFactor, 0.4 * scaleFactor, outlineColour(1), boxColour(1), textColour(1), "Question 3- \nHow long does the hike take?");
                        // Question
                        ctx.font = `48px ${textFont}`;
                    });
                }
            });
            // Handler for receiving results of scoreboard/leaderboard from server. 
            room.onMessage('results', (message) => {
                setPlayerScores(message);
            })
            // Handler for receiver what the answer to the question is.
            room.onMessage('answer', (message) => {
                setAnswer(message);
            });
        }
    }, [currentState, room, metadataLoaded, playerScores, store.selectedTile, awaitingSync, setAwaitingSync, setVideoState, setState, store.currentState]);

    // 3. Setup join/leave handlers
    useEffect(() => {
        if (room) {
            room.onLeave(() => {
                setIsConnected(false);
            });
            room.onJoin(() => {
                setIsConnected(true);
                setShouldSyncImmediately(true);
            });
        }
    }, [room, setShouldSyncImmediately]);

    useEffect(() => {
        if (!videoRef.current) return;
        const video = videoRef.current;
        const localTimeUpdateInterval = setInterval(() => {
            setVideoState(prevState => ({ ...prevState, currentTime: video.currentTime }));
        }, 1000);
        return () => {
            clearInterval(localTimeUpdateInterval);
        };
    }, [setVideoState]);

    // Server sync setup
    useEffect(() => {
        let syncInterval;
        if (isConnected && room) {
            syncInterval = setInterval(() => {
                //const video = videoRef.current;
                //console.log("Sending videoUpdate to server", { currentTime: video.currentTime });
                //TEST DELETE AFTER!
                // room.send('playerAnswer', 'answerOneName');
            }, 10000);
            if (shouldSyncImmediately) {
                console.log("Sync Running...");
                const video = videoRef.current;
                console.log("Immediate sync: Sending videoUpdate to server", { currentTime: video.currentTime });
                //room.send('videoUpdate', { videoState: { currentTime: video.currentTime } });
                setShouldSyncImmediately(false);
            }
        }
        return () => {
            clearInterval(syncInterval);
        };
    }, [isConnected, room, shouldSyncImmediately, setShouldSyncImmediately, ]);

    useEffect(() => {
        if (!videoRef.current) return;
        const video = videoRef.current;
        const tolerance = 0.5;
        if (isLocallyPaused) {
            video.pause();
        } else {
            video.play();
            if (Math.abs(video.currentTime - videoState.currentTime) > tolerance) {
                video.currentTime = videoState.currentTime;
            }
        }
    }, [videoState, isLocallyPaused]);

    // Cleanup
    useEffect(() => {
        return () => {
            if (videoRef.current) {
                videoRef.current.pause();
                videoRef.current.currentTime = 0;
            }
        };
    }, []);

    const videoMaterial = videoTexture ? new MeshBasicMaterial({ map: videoTexture }) : null;
    const videoScreenGeometry = new PlaneGeometry(16, 9);

    // The x and y are the top left corner of the box.
    function drawBox(ctx, x, y, width, height, cornerRadius, strokeColour, fillColour, textColour, text) {
        ctx.lineWidth = 5;
        ctx.beginPath();
        // Top left corner
        ctx.arc(x + cornerRadius, y + cornerRadius, cornerRadius, Math.PI, Math.PI * 1.5);
        // Top right corner
        ctx.arc(x + width - cornerRadius, y + cornerRadius, cornerRadius, Math.PI * 1.5, Math.PI * 2);
        // Bottom right corner
        ctx.arc(x + width - cornerRadius, y + height - cornerRadius, cornerRadius, 0, Math.PI * 0.5);
        // Bottom left corner
        ctx.arc(x + cornerRadius, y + height - cornerRadius, cornerRadius, Math.PI * 0.5, Math.PI);
        // Close the path to create a closed shape
        ctx.closePath();
        // Fill or stroke the rounded rectangle as needed
        ctx.fillStyle = fillColour;
        ctx.fill();
        ctx.strokeStyle = strokeColour;
        ctx.stroke();
        ctx.font = `bold 72px ${textFont}`;
        ctx.fillStyle = textColour;

        // Calculate the height of the text
        const textMetrics = ctx.measureText(text);
        const textHeight = textMetrics.actualBoundingBoxAscent + textMetrics.actualBoundingBoxDescent;

        const lines = text.split('\n');

        // Calculate the total height of all the text lines
        const totalTextHeight = lines.length * textHeight;

        // Calculate the startY value to center the text vertically
        const startY = y + (height - totalTextHeight) / 2 + textMetrics.actualBoundingBoxAscent;

        for (let i = 0; i < lines.length; i++) {
            const line = lines[i];
            const textY = startY + i * textHeight;

            const parts = line.split('**');
            let xPosition = x + 48;
            let textFontWeight = 900;
            for (let j = 0; j < parts.length; j++) {
                const part = parts[j];
                if (j % 2 === 0) {
                    // Regular text, set the font style to regular
                    ctx.font = `normal 60px ${textFont}`;
                } else {
                    // Bold text, set the font style to bold
                    ctx.font = `${textFontWeight} 72px ${textFont}`;
                    // Increase the line width to make the bold text thicker
                    ctx.lineWidth = 3; // Adjust this value to make it thicker

                    // Draw the text twice with a slight offset for a thicker appearance
                    ctx.strokeText(part, xPosition, textY);
                }

                // Draw the part of the text
                ctx.fillText(part, xPosition, textY);

                ctx.lineWidth = 5; // Reset to your desired default value

                // Update the xPosition for the next part
                xPosition += ctx.measureText(part).width;
            }
            // ctx.fillText(line, x + 48, textY, width);
        }
    }

    // The x and y are the bottom left corner of name tag.
    // Total height of the component is the height + nameBarDistance
    function drawBar(ctx, x, y, width, height, cornerRadius, strokeColour, fillColour, text, nameBarDistance) {
        const barY = y - nameBarDistance;
        ctx.lineWidth = 5;
        ctx.beginPath();
        ctx.moveTo(x, barY);
        //ctx.arc(x + cornerRadius, barY + cornerRadius - height, cornerRadius, Math.PI, Math.PI * 1.5);
        //ctx.arc(x + width - cornerRadius, barY + cornerRadius - height, cornerRadius, Math.PI * 1.5, Math.PI * 2);
        ctx.arc(x + cornerRadius, barY - height, cornerRadius, Math.PI, Math.PI * 1.5);
        ctx.arc(x + width - cornerRadius, barY - height, cornerRadius, Math.PI * 1.5, Math.PI * 2);
        ctx.lineTo(x + width, barY);
        ctx.fillStyle = fillColour;
        ctx.fill();
        ctx.strokeStyle = strokeColour;
        ctx.stroke();
        ctx.fillStyle = 'white';
        ctx.textAlign = 'center';
        ctx.fillText(text, x + width / 2, y, width);
    }

    // The x and y are the bottom left corner of the name tag of the left-most bar.
    // data is a list of objects
    /*
        data = [
            {
                name: 'Player1',
                score: 100
            }
        ]
    */
    // Bar sizing areas is the area decimal of the total sum
    function drawChart(ctx, x, y, maxWidth, maxHeight, barSizingArea, dataArray) {
        if (dataArray.length === 0) {
            return
        }
        if (dataArray.length === 1) {
            barSizingArea = 1;
        }
        const barWidth = (maxWidth * barSizingArea) / dataArray.length;
        const spacingWidth = (maxWidth * (1 - barSizingArea) / (dataArray.length - 1));
        let maxScore = dataArray[0].score;
        for (const data of dataArray) {
            maxScore = Math.max(maxScore, data.score);
        }
        const curveRadius = barWidth * 0.05;
        const barTextSpacing = 50;
        let normalizer = 0;
        if (maxScore != 0) {
            normalizer = (maxHeight - curveRadius - barTextSpacing) / maxScore;
        }

        let runningX = x;
        let i = 0;
        for (const data of dataArray) {
            const barHeight = (data.score * normalizer);
            const textX = runningX + barWidth / 2;
            const textY = y - barHeight; // Adjust this value to position the score text
            const scoreText = data.score.toString();


            //drawBar(ctx, runningX, y, barWidth, barHeight, barHeight < barWidth * 0.25 ? barHeight < barWidth : barWidth * 0.25, 'blue', 'white', data.name, 20);
            drawBar(ctx, runningX, y, barWidth, barHeight, curveRadius, 'blue', 'white', data.name, barTextSpacing);

            ctx.fillStyle = 'black'; // Set the text color
            ctx.font = `38px ${textFont}`; // Set the font for the score text
            ctx.textAlign = 'center';
            if (i === 0 && scoreText != 0) {
                ctx.fillText(scoreText, textX, textY);
            }
            i++;
            runningX = runningX + spacingWidth + barWidth;
        }
    }

    function drawProximityTile(state) {
        let isQuestion = state === 'questionState';
        return (
            <group>
                {/*top right*/}
                <ProximityTile
                    characterRef={characterRef}
                    position={[-2.75, 0, 5]}
                    rotation={[0, Math.PI, 0]}
                    size={[5, 0.2, 5]}
                    ID={'B'}
                    socket={room}
                    isQuestion={isQuestion}
                    answer={answer}
                />
                {/*top left*/}
                <ProximityTile
                    characterRef={characterRef}
                    position={[2.75, 0, 5]}
                    rotation={[0, Math.PI, 0]}
                    size={[5, 0.2, 5]}
                    ID={'A'}
                    socket={room}
                    isQuestion={isQuestion}
                    answer={answer}
                />
                {/*bottom right*/}
                <ProximityTile
                    characterRef={characterRef}
                    position={[-2.75, 0, -.5]}
                    rotation={[0, Math.PI, 0]}
                    size={[5, 0.2, 5]}
                    ID={'D'}
                    socket={room}
                    isQuestion={isQuestion}
                    answer={answer}
                />
                {/*bottom left*/}
                <ProximityTile
                    characterRef={characterRef}
                    position={[2.75, 0, -.5]}
                    rotation={[0, Math.PI, 0]}
                    size={[5, 0.2, 5]}
                    ID={'C'}
                    socket={room}
                    isQuestion={isQuestion}
                    answer={answer}
                />
            </group>
        )
    }

    const questionAndAnswers = {
        question0Options: {
            A: 'Cusco',
            B: 'Toronto',
            C: 'Sao Paulo',
            D: 'New York'
        },
        question1Options: {
            A: 'Bruce',
            B: 'Salkantay',
            C: 'Apalachian',
            D: 'Turner'
        },
        question2Options: {
            A: '1 Day',
            B: '10 Days',
            C: '9 to 20 Days',
            D: '5 to 8 Days'
        }
    }

    const [textDriver, setTextDriver] = useState(() => (x) => { });

    const customDrawQuestionsFunction = useMemo(() => {
        if (currentState === undefined) {
            return;
        }
        const isQuestion = currentState.startsWith('question');
        const questionSet = `question${currentState.startsWith('questionState') ? currentState['questionState'.length] : (currentState.startsWith('answerState') ? currentState['answerState'.length] : 0)}Options`;
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        const scaleFactor = 120;
        const w = 16 * scaleFactor;
        const h = 9 * scaleFactor;
        const width = 6.3 * scaleFactor;
        const height = scaleFactor;
        const x = w / 2 - width;
        const y = 5 * scaleFactor;
        const cornerRadius = height / 2;
        const outlineColour = (alpha) => { return `rgba(100, 100, 100, ${alpha})` };
        const boxColour = (alpha) => { return `rgba(255, 255, 255, ${alpha})` };
        const textColour = (alpha) => { return `rgba(0, 0, 0, ${alpha})` };


        canvas.width = w; // Set the width of the canvas
        canvas.height = h; // Set the height of the canvas
        ctx.drawImage(questionBackgroundImage, 0, 0);

        textDriver(ctx)

        if (isQuestion) {
            drawBox(ctx, x, y, width, height, cornerRadius, outlineColour(1), boxColour(1), textColour(1), "**A** | " + questionAndAnswers[questionSet]['A']);
            drawBox(ctx, x + width + 48, y, width, height, cornerRadius, outlineColour(1), boxColour(1), textColour(1), "**B** | " + questionAndAnswers[questionSet]['B']);
            drawBox(ctx, x, y + height + 48, width, height, cornerRadius, outlineColour(1), boxColour(1), textColour(1), "**C** | " + questionAndAnswers[questionSet]['C']);
            drawBox(ctx, x + width + 48, y + height + 48, width, height, cornerRadius, outlineColour(1), boxColour(1), textColour(1), "**D** | " + questionAndAnswers[questionSet]['D']);
        }
        else {
            let isAnswer = answer === 'A'
            drawBox(ctx, x, y, width, height, cornerRadius, isAnswer ? outlineColour(1) : outlineColour(0.5), isAnswer ? boxColour(1) : boxColour(0.5), isAnswer ? textColour(1) : textColour(0.5), "**A** | " + questionAndAnswers[questionSet]['A']);
            isAnswer = answer === 'B'
            drawBox(ctx, x + width + 48, y, width, height, cornerRadius, isAnswer ? outlineColour(1) : outlineColour(0.5), isAnswer ? boxColour(1) : boxColour(0.5), isAnswer ? textColour(1) : textColour(0.5), "**B** | " + questionAndAnswers[questionSet]['B']);
            isAnswer = answer === 'C'
            drawBox(ctx, x, y + height + 48, width, height, cornerRadius, isAnswer ? outlineColour(1) : outlineColour(0.5), isAnswer ? boxColour(1) : boxColour(0.5), isAnswer ? textColour(1) : textColour(0.5), "**C** | " + questionAndAnswers[questionSet]['C']);
            isAnswer = answer === 'D'
            drawBox(ctx, x + width + 48, y + height + 48, width, height, cornerRadius, isAnswer ? outlineColour(1) : outlineColour(0.5), isAnswer ? boxColour(1) : boxColour(0.5), isAnswer ? textColour(1) : textColour(0.5), "**D** | " + questionAndAnswers[questionSet]['D']);
        }

        const texture = new THREE.CanvasTexture(canvas);
        texture.needsUpdate = true;
        return texture;
    }, [currentState, answer]);



    const drawChartFunction = useMemo(() => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        let scaleFactor = 120;
        let w = 16 * scaleFactor;
        let h = 9 * scaleFactor;
        canvas.width = w; // Set the width of the canvas
        canvas.height = h; // Set the height of the canvas
        ctx.drawImage(questionBackgroundImage, 0, 0);

        // Header
        ctx.font = `bold 96px ${textFont}`;
        ctx.fillStyle = 'white';
        ctx.fillText('RESULTS', 4 * 48, 1.5 * 120);

        ctx.font = `48px ${textFont}`;
        ctx.fillStyle = 'white';

        const x = 240;
        const y = h - 72;
        const width = w - 2 * x;
        const height = y - 360;
        //const cornerRadius = height / 2;
        //const questionSet = 'questionThreeOptions';

        if (playerScores != null) {
            ctx.fillText(`Congratulations ${playerScores[0].name}!`, 4 * 48, 1.5 * 120 + 72);
            drawChart(ctx, x, y, width, height, 0.75, playerScores);
        }

        const texture = new THREE.CanvasTexture(canvas);
        texture.needsUpdate = true;
        return texture;
    }, [currentState]);



    return (
        <>

            {videoMaterial && currentState === 'videoState' && (
                <>
                    <mesh
                        geometry={videoScreenGeometry}
                        material={videoMaterial}
                        position={[0, 10, 30]}
                        rotation={[0, Math.PI, 0]}
                        scale={[1.8, 1.8, 1.8]}
                    />
                </>
            )}
            {currentState === 'instructionState' && (
                <>
                    <DynamicTextureTV
                        textureDataURL={instructionScreen}
                        drawFunction={() => {
                            const canvas = document.createElement('canvas');
                            const ctx = canvas.getContext('2d');
                            let scaleFactor = 120;
                            let w = 16 * scaleFactor;
                            let h = 9 * scaleFactor;
                            canvas.width = w; // Set the width of the canvas
                            canvas.height = h; // Set the height of the canvas
                            ctx.drawImage(instructionScreenImage, 0, 0);
                            const texture = new THREE.CanvasTexture(canvas);
                            texture.needsUpdate = true;
                            return texture;
                        }}
                    ></DynamicTextureTV>
                </>
            )}
            {/* if state == question state: then render prox tiles */}
            {currentState === 'questionState0' && (
                <>
                    <DynamicTextureTV
                        textureDataURL={questionBackground}
                        drawFunction={customDrawQuestionsFunction}
                    ></DynamicTextureTV>
                    {/* Wrap all the ProximityTile components in a parent element */}
                    {drawProximityTile('questionState')}
                </>
            )}
            {currentState === 'answerState0' && (
                <>
                    <DynamicTextureTV
                        textureDataURL={questionBackground}
                        drawFunction={customDrawQuestionsFunction}
                    ></DynamicTextureTV>
                    {drawProximityTile('answerState')}
                </>
            )}
            {currentState === 'questionState1' && (
                <>
                    <DynamicTextureTV
                        textureDataURL={questionBackground}
                        drawFunction={customDrawQuestionsFunction}
                    ></DynamicTextureTV>

                    {drawProximityTile('questionState')}
                </>
            )}
            {currentState === 'answerState1' && (
                <>
                    <DynamicTextureTV
                        textureDataURL={questionBackground}
                        drawFunction={customDrawQuestionsFunction}
                    ></DynamicTextureTV>
                    {drawProximityTile('answerState')}
                </>
            )}
            {currentState === 'questionState2' && (
                <>
                    <DynamicTextureTV
                        textureDataURL={questionBackground}
                        drawFunction={customDrawQuestionsFunction}
                    ></DynamicTextureTV>
                    {drawProximityTile('questionState')}
                </>
            )}
            {currentState === 'answerState2' && (
                <>
                    <DynamicTextureTV
                        textureDataURL={questionBackground}
                        drawFunction={customDrawQuestionsFunction}
                    ></DynamicTextureTV>
                    {drawProximityTile('answerState')}
                </>
            )}
            {currentState === 'resultState' && (
                <>
                    <DynamicTextureTV
                        textureDataURL={questionBackground}
                        drawFunction={drawChartFunction}
                    ></DynamicTextureTV>
                </>
            )}
        </>
    );

}