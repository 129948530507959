import React, { useState } from 'react';
import './ViewSelection.scss';
import ThirdPersonIconActive from '../../../assets/SVG/ThirdPersonIconActive.svg';
import ThirdPersonIconInactive from '../../../assets/SVG/ThirdPersonIconInactive.svg';
import FirstPersonIconActive from '../../../assets/SVG/FirstPersonIconActive.svg';
import FirstPersonIconInactive from '../../../assets/SVG/FirstPersonIconInactive.svg';

const ViewSelection = () => {
  const OPTION_1ST_PERSON = '1st Person';
  const OPTION_3RD_PERSON = '3rd Person';

  const [activeOption, setActiveOption] = useState(OPTION_3RD_PERSON);

  const handleToggle = () => {
    setActiveOption(prevOption =>
      prevOption === OPTION_1ST_PERSON ? OPTION_3RD_PERSON : OPTION_1ST_PERSON
    );
  };

  return (
    <div className="toggle-container">
      <div
        className={`toggle-option toggle-option--left ${activeOption === OPTION_3RD_PERSON ? 'active' : ''}`}
        onClick={handleToggle}
      >
        <img className="toggle-icon" 
          src={activeOption===OPTION_3RD_PERSON ? ThirdPersonIconActive : ThirdPersonIconInactive} 
          alt="Third Person Icon" />
        <span>
          {OPTION_3RD_PERSON}
        </span>
      </div>
      <div
        className={`toggle-option toggle-option--right ${activeOption === OPTION_1ST_PERSON ? 'active' : ''}`}
        onClick={handleToggle}
      >
        <img className="toggle-icon" 
          src={activeOption===OPTION_1ST_PERSON ? FirstPersonIconActive : FirstPersonIconInactive} 
          alt="First Person Icon" />
        <span>
          {OPTION_1ST_PERSON}
        </span>
      </div>
    </div>
  );
};

export default ViewSelection;
