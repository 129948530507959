// IconButton.js
import React from 'react';
import './IconButton.css';

function IconButton({ icon, onClick, isActive, small }) {
  return (
    <button className={`icon-button ${isActive ? 'active' : ''} ${small ? 'small' : ''}`} onClick={onClick}>
      {icon}
    </button>
  );
}

export default IconButton;
