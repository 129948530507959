// Working v0
import React, { useEffect, useState, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { useVideoState } from '../contexts/VideoStateContext';
import { Html } from "@react-three/drei"
import GlobalStoreContext from './GlobalNav/store/GlobalStore';
import { timeRemaining } from './HelperFunctions';
import { useSocket } from '../contexts/SocketContext';

const TimerOverlay = () => {
  const location = useLocation();
  const { videoState, isLocallyPaused, setIsLocallyPaused, setShouldSyncImmediately, awaitingSync, setAwaitingSync } = useVideoState();
  const { currentTime, duration } = videoState;

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [smoothCurrentTime, setSmoothCurrentTime] = useState(currentTime);
  const [timeLeft, setTimeLeft] = useState(0);
  const [progressPercentage, setProgressPercentage] = useState(0)
  const { store } = useContext(GlobalStoreContext);
  const { room } = useSocket();
  const [currentState, setState] = useState('videoStat');
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const overlayStyle = isMobile
    ? { right: '16px', left: 'auto', bottom: '42px' }
    : { left: '50%', bottom: '24px', transform: 'translateX(-50%)' };

  useEffect(() => {
    const timeInformation = store.timeInformation;
    setTimeLeft(timeRemaining(store.timeInformation))
    const [currentTimeStr, durationStr] = timeInformation.split('/');
    const currentTimeInSeconds = parseTime(currentTimeStr);
    const durationInSeconds = parseTime(durationStr);
    setProgressPercentage((currentTimeInSeconds / durationInSeconds) * 100)
    function parseTime(timeStr) {
      const [minutes, seconds] = timeStr.split(':');
      return parseInt(minutes, 10) * 60 + parseInt(seconds, 10);
    }
  }, [store.timeInformation])

  useEffect(() => {
    if (room) {
      room.onMessage('currentState', (message) => {
        setState(message);
      })
    }
  }, [room, currentState])

  //   const timeLeft = 
  const progressBarWidth = isMobile ? '84px' : '128px';

  //   const playIconPath = `${process.env.PUBLIC_URL}/assets/playIcon.svg`;
  //   const pauseIconPath = `${process.env.PUBLIC_URL}/assets/pauseIcon.svg`;

  // Parse the time information string to extract current time and video duration


  // Calculate the progress percentage


  // Function to parse time in format "0:00" to seconds

  switch (currentState.slice(0, -1)) {
    case 'videoStat':
      return (
        <div style={{
          position: 'fixed',
          display: 'flex',
          alignItems: 'center',
          padding: '16px',
          borderRadius: '12px',
          backgroundColor: 'rgba(0, 0, 0, 0.7)',
          zIndex: 1000,
          ...overlayStyle
        }}>
          <img
            src={`${process.env.PUBLIC_URL}/assets/PS_Logo.png`}
            alt="Video Thumbnail"
            style={{
              width: '50px',
              height: '50px',
              marginRight: '16px',
              borderRadius: '8px'
            }}
          />
          <div>
            <div style={{ color: 'white', marginBottom: '8px' }}>Video Title</div>
            <div style={{ color: 'white' }}>
              <div style={{
                width: progressBarWidth,
                height: '12px',
                backgroundColor: '#ccc',
                borderRadius: '6px',
                position: 'relative'
              }}>
                <div style={{
                  width: `${progressPercentage}%`,
                  height: '100%',
                  backgroundColor: '#6a6a6a',
                  borderRadius: '6px'
                }}></div>
              </div>
            </div>
          </div>
        </div>
      );
    case 'questionState':
    case 'answerState':
      return (
        <div style={{
          position: 'fixed',
          display: 'flex',
          alignItems: 'center',
          padding: '16px',
          borderRadius: '12px',
          backgroundColor: 'rgba(0, 0, 0, 0.7)',
          zIndex: 1000,
          width: '250px',
          ...overlayStyle
        }}>
          <img
            src={`${process.env.PUBLIC_URL}/assets/PS_Logo.png`}
            alt="Video Thumbnail"
            style={{
              width: '50px',
              height: '50px',
              marginRight: '16px',
              borderRadius: '8px'
            }}
          />
          <div>
            <div style={{ color: 'white', marginBottom: '8px' }}>Time Remaining <br />{timeLeft} seconds</div>
            <div style={{ color: 'white' }}>
              <div style={{
                width: `100%`,
                height: '100%',
                backgroundColor: '#6a6a6a',
                borderRadius: '6px'
              }}>
              </div>
            </div>
          </div>

        </div>
      );
    default:
      return;
  }

};

export default TimerOverlay;
