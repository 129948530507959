import { createContext, useContext, useState } from 'react'
export const GlobalStoreContext = createContext({});

function GlobalStoreContextProvider(props) {

    const [store, setStore] = useState({
        location: null,
        mapOpen: false,
        selectedTile: null,
        timeInformation: '0:00/0:00',
    });

    const storeReducer = (action) => {
        const { type, payload } = action;
        switch (type) {
            case 'setLocation': {
                return setStore({
                    ...store,
                    location: payload
                });
            }
            case 'toggleMap': {
                return setStore({
                    ...store,
                    mapOpen: payload
                });
            }
            case 'setSelectedTile': {
                return setStore({
                    ...store,
                    selectedTile: payload
                });
            }
            case 'setTimeInformation': {
                return setStore({
                    ...store,
                    timeInformation: payload
                });
            }
            case 'setChatMessages': {
                return setStore({
                    ...store,
                    chatMessages: payload
                });
            }
        }
    }
    store.setLocation = function(location){
        console.log("\nSetting location in Global Store.");
        storeReducer({
            type: 'setLocation',
            payload: location
        });
    }
    store.toggleMap = function(mapVisible){
        console.log("Toggling map in Global Store.");
        storeReducer({
            type: 'toggleMap',
            payload: mapVisible
        })
    }
    store.setSelectedTile = function(selectedTile){
        storeReducer({
            type: 'setSelectedTile',
            payload: selectedTile
        });
    }
    store.setTimeInformation = function(timeInformation) {
        storeReducer({
            type: 'setTimeInformation',
            payload: timeInformation
        });
    }
    return (
        <GlobalStoreContext.Provider value={{store}}>
            {props.children}
        </GlobalStoreContext.Provider>
    );
}
export default GlobalStoreContext;
export { GlobalStoreContextProvider };