import React, { useState, useEffect, useContext } from 'react';
import './GlobalNav.css';
import IconButton from './IconButton';
import Map from './Map';
import Settings from './Settings';
import Chat from './Chat';
import { ReactComponent as Icon1 } from '../../assets/SVG/MapMarker.svg';
import { ReactComponent as Icon2 } from '../../assets/SVG/Messages.svg';
import { ReactComponent as Icon3 } from '../../assets/SVG/Settings.svg';
import { ReactComponent as Logo } from '../../assets/SVG/TerraTrekLogo.svg';
import { ReactComponent as MobileLogo } from '../../assets/SVG/MobileLogo.svg';
import GlobalStoreContext from './store/GlobalStore';

function GlobalNav() {
  const { store } = useContext(GlobalStoreContext);
  const [overlay, setOverlay] = useState(null);
  const [activeButton, setActiveButton] = useState(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const isMobile = windowWidth <= 768;

  const closeOverlay = () => {
    setOverlay(null);
    toggleOverlay('Map')
  };
  const toggleOverlay = (overlayName) => {
    if (overlay === overlayName) {
      if (overlayName === "Map") {
        store.toggleMap(false);
        console.log("store.mapOpen = " + store.mapOpen);
      }
      setOverlay(null);
      setActiveButton(null);
    } else {
      if (overlayName === "Map") {
        store.toggleMap(true);
        console.log("store.mapOpen = " + store.mapOpen);
      }
      setOverlay(overlayName);
      setActiveButton(overlayName);
    }
  };

  return (
    <div className="global-nav">
      <div className="logo">{isMobile ? <MobileLogo /> : <Logo />}</div>
      <div className="icons">
        <IconButton icon={<Icon1 />} onClick={() => toggleOverlay('Map')} />
        <IconButton icon={<Icon2 />} isActive={activeButton === 'Chat'} onClick={() => toggleOverlay('Chat')} />
        <IconButton icon={<Icon3 />} isActive={activeButton === 'Settings'} onClick={() => toggleOverlay('Settings')} />
      </div>
      {overlay === 'Map' && <Map closeOverlay={closeOverlay} />}
      {/*Does this re-render Chat from the start?*/}
      {overlay === 'Chat' && <Chat />}
      {overlay === 'Settings' && <Settings />}
    </div>
  );
}

export default GlobalNav;
