import React, { useState, useContext } from 'react';
import './Overlays.scss';
import theatre from "../../assets/SVG/Theatre.svg"
import social from "../../assets/SVG/Social.svg"
import showcase from '../../assets/SVG/Showcase.svg'
import MapElement from "../MapElement";
import GlobalStoreContext from './store/GlobalStore';
import { useNavigate } from 'react-router';

function Map({ closeOverlay }) {
  const { store } = useContext(GlobalStoreContext);
  const navigate = useNavigate();
  const closeMap = () => {
    navigate("/");
    store.toggleMap(false);
  }
  const goToTheater = () => {
    navigate("/VideoScene");
    store.toggleMap(false);
  }
  // const [location,setLocation] = useState("")
  // const toggleLocation = (location) => {
  //     if(location === location){
  //         setLocation("");
  //     }
  //     else {
  //         setLocation(location);
  //     }
  // }
  return (
    <div className="map">
      {/* <p>Location: {location}</p> */}
      <button className="map-exit-button" onClick={closeOverlay}>X</button>
      <MapElement elementName={"Plaza"} elementIcon={showcase} descriptionText={'Place under construction, come back later!'} buttonText={'Coming Soon!'} isliveButton={false} />
      <MapElement elementName={"Theater"} elementIcon={theatre} descriptionText={'Come watch videos together with others!'} buttonText={'Explore Theatre'} />
      <MapElement elementName={"Social"} elementIcon={social} descriptionText={'Place under construction, come back later!'} buttonText={'Coming Soon!'} isliveButton={false} />
    </div>
  );
}

export default Map;
