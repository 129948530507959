import React, { useRef} from 'react';
import { useLoader, useFrame } from '@react-three/fiber';
import { Stars } from '@react-three/drei';
import { useThree} from '@react-three/fiber';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import {DoubleSide} from 'three';
import {WaveTextureMaterial} from '../shaders/WaveTextureMaterial'

const Background = () => {
  const { gl } = useThree();
  
  React.useEffect(() => {
    gl.setClearColor(0x000000);
  }, [gl]);
  
  return null;
};


  function PlaneWaveTexture() {
    const materialRef = useRef();

    useFrame(({ clock }) => {
      if (materialRef.current) {
        materialRef.current.uniforms.time.value = clock.getElapsedTime();
      }
    });

    return (
      <mesh receiveShadow rotation={[-Math.PI / 2, 0, 0]} position={[0, -0.5, 100]}>
        <planeBufferGeometry args={[800, 800]} />
        <waveTextureMaterial ref={materialRef} />
      </mesh>
    );
  }
  
const Scene1 = ({ characterRef }) => {

  const pavillion = useLoader(GLTFLoader, process.env.PUBLIC_URL + '/assets/pavillion.gltf');
  const pavillionMesh = pavillion.nodes.Cube001;
  pavillionMesh.material.side = DoubleSide;
  pavillionMesh.scale.setX(pavillionMesh.scale.x);
  pavillionMesh.scale.setY(pavillionMesh.scale.y);
  pavillionMesh.scale.setZ(pavillionMesh.scale.z);

  


  return (
    <>
        <Stars 
          depth={100}
        />
        <ambientLight intensity={1} />
        <directionalLight
          castShadow
          position={[0, 10, -5]}
          intensity={1}
          shadow-mapSize-width={1024}
          shadow-mapSize-height={1024}
          shadow-camera-far={50}
          shadow-camera-left={-10}
          shadow-camera-right={10}
          shadow-camera-top={10}
          shadow-camera-bottom={-10}
        />
        <directionalLight
          castShadow
          position={[0, 2, 0]}
          intensity={1}
        />
       <directionalLight
         castShadow
         position={[0, 2, 0]}
         intensity={1}
         shadow-mapSize-width={1024}
         shadow-mapSize-height={1024}
         shadow-camera-far={50}
         shadow-camera-left={-10}
         shadow-camera-right={10}
         shadow-camera-top={10}
         shadow-camera-bottom={-10}
       />
      <PlaneWaveTexture/>
      {/* <primitive
        object={pavillion.scene}
        receiveShadow
        position={[0, 0, 15]}
        rotation={[0, 0, 0]}
        scale={[2, 2, 2]}
      /> */}
      <Background/>
    </>
  );
};

export default Scene1;
