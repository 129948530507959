// ✅
import { createContext, useContext, useState, useEffect } from 'react';
import { Client } from 'colyseus.js';

const SocketContext = createContext();

export const useSocket = () => {
  return useContext(SocketContext);
};

export const SocketProvider = ({ children }) => {
  const [client, setClient] = useState(null);
  const [room, setRoom] = useState(null);

  useEffect(() => {
    //const endpoint = 'wss://20.84.45.26:3001';
    //const endpoint = 'ws://localhost:3001';
    const endpoint = 'wss://psspacesserver.eastus.cloudapp.azure.com:3001';
    const newClient = new Client(endpoint);
    console.log("new Client: ", newClient);
    setClient(newClient);
  }, []);

  useEffect(() => {
    const joinRoom = async () => {
      if (!client) return;
      const newRoom = await client.joinOrCreate('my_room');
      setRoom(newRoom);
      console.log('Client:', client);
      console.log('Room:', newRoom);
    };

    joinRoom();
  }, [client]);

  return <SocketContext.Provider value={{ client, room }}>{children}</SocketContext.Provider>;
};
