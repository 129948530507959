import {createContext, useContext, useReducer} from "react";
import {produce} from "immer";

const GlobalSettingsContext = createContext();

const SET_DEVICE_TYPE = 'setDeviceType';
const SET_USER_NAME = 'setUserName';

const reducer = (state, action) => {
    switch(action.type){
        case SET_DEVICE_TYPE:
            state.deviceType = action.payload;
            return;
        case SET_USER_NAME:
            state.userName = action.payload;
            return;
        default:
            return;
    }
}

const GlobalSettingsContextProvider = ({children}) => {
    const [settings, dispatch] = useReducer(produce(reducer), {
        deviceType: 'desktop',
        userName: 'Guest'
    });

    const setDeviceType = (deviceType) => {
        dispatch({type: SET_DEVICE_TYPE, payload: deviceType});
    }

    const setUserName = (userName) => {
        dispatch({type: SET_USER_NAME, payload: userName});
    }

    return <GlobalSettingsContext.Provider value={{...settings, setDeviceType, setUserName}}>
        {children}
    </GlobalSettingsContext.Provider>
}

const useGlobalSettingsContext = () => {
    return useContext(GlobalSettingsContext);
}
export {GlobalSettingsContextProvider, useGlobalSettingsContext}
export default GlobalSettingsContext;