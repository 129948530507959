import React, { useState, useRef, useEffect } from 'react';
import { Canvas } from '@react-three/fiber';
import IconButton from './components/IconButton';
import { faThLarge, faUser, faCog } from '@fortawesome/free-solid-svg-icons';
import './App.scss';
import ThirdPersonCamera from './components/ThirdPersonCamera';
import Plaza from './components/Plaza.js';
import Theater from './components/Theater.js';
import Social from './components/Social.js';
import { SocketProvider } from './contexts/SocketContext';
import Multiplayer from './components/Multiplayer';
import { LiveKitProvider } from './contexts/LiveKitContext';
import ScenesHandler from './scenes/ScenesHandler';
import WorldContextProvider from './contexts/WorldContext';
import MobileJoystick from './components/MobileControls';
import Loading from './components/Loading';
import GlobalNav from './components/GlobalNav/GlobalNav';
import { VideoStateProvider } from './contexts/VideoStateContext';
import { Routes, Route } from 'react-router';
import { GlobalStoreContextProvider } from './components/GlobalNav/store/GlobalStore';
import { ChatMessagesContextProvider } from './components/GlobalNav/contexts/ChatMessagesContext';
import { GlobalSettingsContextProvider } from "./contexts/GlobalSettingsContext";
import TimerOverlay from './components/TimerOverlay';

import { toPng } from "html-to-image";
import EnterScreen from './components/EnterScreen';

const App = () => {
  const characterRef = useRef();
  const [isLoading, setIsLoading] = useState(true);
  const [joystickData, setJoystickData] = useState(null);
  const [textureDataURL, setTextureDataURL] = useState("");
  const handleJoystickMove = (data) => {
    if (data === null) {
      setJoystickData(null);
    } else {
      const angle = data.angle.radian;
      const force = data.force;
      setJoystickData({ angle, force });
    }
  };

  const handleIconClick = (iconName) => {
    console.log(`Icon ${iconName} clicked.`);
  };

  useEffect(() => {
    const captureElement = document.getElementById('testing');
    toPng(captureElement)
      .then((dataUrl) => {
        console.log(dataUrl)
        setTextureDataURL(dataUrl);
      })
      .catch((err) => {
        console.error('Error capturing element:', err);
      });
  }, []);
  return (

    <GlobalSettingsContextProvider>
      <LiveKitProvider>
        <SocketProvider>
          <VideoStateProvider>
            <WorldContextProvider>
              <GlobalStoreContextProvider>
                <ChatMessagesContextProvider>
                  <div className="app-container">
                    <Routes>
                      <Route path="/Plaza" element={<Plaza />}></Route>
                      <Route path="/Theater" element={<Theater />}></Route>
                      <Route path="/Social" element={<Social />}></Route>
                    </Routes>
                    <Canvas gl={{ stencil: true }} style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%' }}>
                      <ScenesHandler characterRef={characterRef} textureData={textureDataURL} />
                      <ThirdPersonCamera characterRef={characterRef} joystickData={joystickData} />
                      <Multiplayer />
                    </Canvas>

                    {isLoading && <Loading onLoadComplete={() => setIsLoading(false)} />}
                    <EnterScreen />
                    <TimerOverlay />
                    <MobileJoystick onJoystickMove={(data) => handleJoystickMove(data)} />
                    <GlobalNav />

                  </div>
                </ChatMessagesContextProvider>
              </GlobalStoreContextProvider>
            </WorldContextProvider>

          </VideoStateProvider>
        </SocketProvider>
      </LiveKitProvider>
    </GlobalSettingsContextProvider>
  );
};

export default App;
