import "./mapElements.css"
import caret from "../assets/SVG/Caret.svg"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationDot } from '@fortawesome/free-solid-svg-icons';
import { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import GlobalStoreContext from "./GlobalNav/store/GlobalStore";


function MapElement({ elementName, elementIcon, buttonText, descriptionText, isliveButton = true }) {
    const { store } = useContext(GlobalStoreContext);
    const navigate = useNavigate();
    let exitButton;
    let none = null;

    const updateLocation = (location) => {
        store.setLocation(location);
        if (location === "Theater") {
            navigate("/");
            window.location.reload(false);
        }
        else
            navigate("/" + location);
    }

    const exploreButton = <button id="explore" onClick={isliveButton ? () => { updateLocation(elementName); store.toggleMap(false) } : () => { }}>{buttonText}</button>;
    const currentLocation = <div onClick={() => { updateLocation(""); store.toggleMap(false) }} id="here-div"> <FontAwesomeIcon icon={faLocationDot} /> You Are Here</div>
    let exploreContainer = store.location === elementName ? currentLocation : exploreButton;
    exitButton = store.location === elementName ? exitButton : none;
    return (
        <div className="element-container">
            {exitButton}
            <div id="element-header">
                <h1>{elementName}</h1>
                <img src={elementIcon} alt={elementName} />
            </div>
            <p>{descriptionText}</p>
            {exploreContainer}
        </div>
    )
}

export default MapElement;