import React, { useEffect } from "react";
import { Route, Routes} from "react-router-dom";
import TestScene from "./TestScene";
import Scene1 from "./Scene1";
import { WorldContext } from '../contexts/WorldContext';
import VideoScene from "./VideoScene";
import DynamicTextureTV from "./DynamicTextureTV"
const ScenesHandler = ({ characterRef, textureData }) => {
  const { isWorldInitialized, initializeWorld } = React.useContext(WorldContext);

  /* useEffect(() => {
    if (!isWorldInitialized) {
      initializeWorld();
    }
  }, [isWorldInitialized, initializeWorld]); */

  return (
    <>
      {(
        <Routes>
          {/* <Route
            key="Scene1"
            path="/"
            element={<Scene1 characterRef={characterRef} />}
          />
          <Route
            key="testScene"
            path="/testScene"
            element={<TestScene characterRef={characterRef} />}
          /> */}
          <Route
            key="VideoScene"
            path="/"
            forceRefresh={true}
            element={<VideoScene characterRef={characterRef} />}
          />
          {/* <Route
            key="testScene"
            path="/test-dynamic"
            element={<DynamicTextureTV characterRef={characterRef} textureDataURL={textureData}/>}
          /> */}
        </Routes>
      )}
    </>
  );
};

export default ScenesHandler;
