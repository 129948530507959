

import React, { useEffect, useState } from 'react';
import { PlaneGeometry } from 'three';
import * as THREE from 'three';

function DynamicTextureTV({ characterRef, textureDataURL, drawFunction }) {

    const planeGeo = new PlaneGeometry(16, 9);
    const [image] = useState(new Image());
    // Function to create a canvas texture with HTML content

    //Pre-load any texture to avoid texture flickering
    useEffect(() => {
        image.src = textureDataURL;
    }, [image]);

    let createHtmlTexture = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        canvas.width = 512; // Set the width of the canvas
        canvas.height = 512; // Set the height of the canvas

        // Add HTML content to the canvas
        ctx.font = '40px Arial';
        ctx.fillStyle = 'white';
        //ctx.drawImage(image, 0, 0);
        ctx.fillText('bonjour', 50, 100);

        const texture = new THREE.CanvasTexture(canvas);
        texture.needsUpdate = true;
        return texture;
    };

    if(typeof drawFunction === 'function') 
    {
        createHtmlTexture = drawFunction;
    }
    else if(drawFunction) {
        createHtmlTexture = () => {return drawFunction};
    }
    

    return (
        <mesh receiveShadow
            position={[0, 10, 30]}
            rotation={[0, Math.PI, 0]}
            scale={[1.8, 1.8, 1.8]} geometry={planeGeo} >
            <meshBasicMaterial map={createHtmlTexture()} attach="material" />
        </mesh>
    );
}

export default DynamicTextureTV;

