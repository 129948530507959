import React, { useRef, useState, useContext, useEffect, useMemo } from 'react';
import { Stars, Sky, useGLTF } from '@react-three/drei';
import { useThree } from '@react-three/fiber';
import { WorldContext } from '../contexts/WorldContext';
import { VideoStateContext } from '../contexts/VideoStateContext';
import { DoubleSide, MeshBasicMaterial, PlaneGeometry, Mesh, VideoTexture } from 'three';
import { useSocket } from '../contexts/SocketContext';
import * as THREE from 'three'
import { extend, useLoader, useFrame } from '@react-three/fiber'
import { Water } from 'three-stdlib'
import VideoPlayer from '../components/VideoPlayer';
import ProximityTile from '../components/ProximityTile';
import TerrainManager from '../components/Terrain/TerrainManager';


extend({ Water })

function Ocean() {
  const ref = useRef()
  const gl = useThree((state) => state.gl)
  const waterNormals = useLoader(THREE.TextureLoader, '/waternormals.jpeg')
  waterNormals.wrapS = waterNormals.wrapT = THREE.RepeatWrapping
  const geom = useMemo(() => new THREE.PlaneGeometry(10000, 10000), [])
  const config = useMemo(
    () => ({
      textureWidth: 512,
      textureHeight: 512,
      waterNormals,
      sunDirection: new THREE.Vector3(),
      sunColor: 0xffffff,
      waterColor: 0x001e0f,
      distortionScale: 3.7,
      fog: false,
      format: gl.encoding
    }),
    [waterNormals]
  )
  useFrame((state, delta) => (ref.current.material.uniforms.time.value += delta))
  return <water ref={ref} args={[geom, config]} rotation-x={-Math.PI / 2} />
}

function VideoSceneEnvModel() {
  const gltf = useGLTF(process.env.PUBLIC_URL + '/assets/videoSceneEnv2.glb', false);

  useEffect(() => {
    // Function to traverse and update material color
    const setColor = (obj) => {
      if (obj.isMesh) {
        obj.material.color.set(0xc7c7c7);
      }
    };
    
    // Traverse the model and set the color
    gltf.scene.traverse(setColor);
  }, [gltf.scene]);
  
  return (
      <primitive 
        object={gltf.scene} 
        dispose={null} 
        position={[0, 0.02, 55]}
        scale={[8, 3, 8]}
        rotation={[0, Math.PI / 2, 0]}
      />
    );
}

const Background = () => {
  const { gl } = useThree();
  useEffect(() => {
    gl.setClearColor('black');
  }, [gl]);
  return null;
};

const VideoScene = ({ characterRef }) => {
 

  return (
    <>
      <Stars />
      <ambientLight intensity={1} />
      <directionalLight
        castShadow
        position={[0, 10, -5]}
        intensity={1}
        shadow-mapSize-width={1024}
        shadow-mapSize-height={1024}
        shadow-camera-far={50}
        shadow-camera-left={-10}
        shadow-camera-right={10}
        shadow-camera-top={10}
        shadow-camera-bottom={-10}
      />
      <directionalLight
        castShadow
        position={[0, 2, 50]}
        intensity={0}
      />
      <mesh receiveShadow rotation={[-Math.PI / 2, 0, 0]} position={[0, -0.5, 100]}>
        <planeBufferGeometry attach="geometry" args={[300, 300]} />
        <meshStandardMaterial attach="material" color={'#808080'} />
      </mesh>
      <Sky scale={1000} sunPosition={[500, 150, -1000]} turbidity={0.1} />
      <Background />

      {/* <Ocean/> */}
      <VideoPlayer videoPath={process.env.PUBLIC_URL + '/assets/HikingVideo.mp4'} characterRef={characterRef}/>
      {/*We can also put a URL link in to do this.*/}
      {/* <VideoPlayer videoPath={'https://download.samplelib.com/mp4/sample-5s.mp4'}/> */}
      <Ocean/>
      <TerrainManager/>
      <VideoSceneEnvModel/>


    </>
  );
};

export default VideoScene;
