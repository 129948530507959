// Converts a minute decimal to minute:second format
export function minuteDecimalToMinuteSecond(minutes) {
    let fullMins = Math.floor(minutes);
    let partialMins = minutes - fullMins;
    let seconds = 60 * partialMins;
    return `${fullMins}:${Math.round(seconds)}`;
}

// converts seconds to minute:second format
export function secondsToMinuteSecond(seconds) {
    let roundedSeconds = Math.round(seconds);
    let fullMins = Math.floor(roundedSeconds / 60);
    let remainingSeconds = roundedSeconds - fullMins * 60;
    return `${fullMins}:${remainingSeconds}`;
}

//time remaining function, takes in 0:00/0:00 format
export function timeRemaining(time){
    let [currTime,finalTime] = time.split("/"); 
    let [currTimeMin, currTimeSec] = currTime.split(":");
    let [finalTimeMin,finalTimeSec] = finalTime.split(":");
    let currTimeSeconds = parseInt(currTimeMin)*60+parseInt(currTimeSec);
    let finalTimeSeconds = parseInt(finalTimeMin)*60+parseInt(finalTimeSec);
    return finalTimeSeconds-currTimeSeconds;
}
